import React from 'react';
import { bool, node } from 'prop-types';

const propTypes = {
  children: node.isRequired,
  caret: bool,
};

const defaultProps = {
  caret: false,
};

const Card = ({ children, caret }) => {
  return (
    <div className="shadow p-4 bg-white rounded position-relative">
      {children}
      {caret && <span className="ui-caret" />}
    </div>
  );
};

Card.propTypes = propTypes;
Card.defaultProps = defaultProps;

export default Card;
