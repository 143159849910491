import React from 'react';
import { arrayOf, shape, string } from 'prop-types';
import { Link } from 'gatsby';

import Card from '../Card/Card';

const propTypes = {
  title: string.isRequired,
  items: arrayOf(
    shape({
      uri: string.isRequired,
      name: string.isRequired,
    }),
  ).isRequired,
};

const ListWidget = ({ title, items }) => {
  return (
    <div className="ui-list-widget">
      <Card>
        <h3 className="h4">{title}</h3>
        <ul className="ui-list-widget__list">
          {items.map((item) => {
            return (
              <li key={item.uri}>
                <Link
                  className="ui-list-widget__item text-decoration-none"
                  to={item.uri}
                >
                  {item.name}
                </Link>
              </li>
            );
          })}
        </ul>
      </Card>
    </div>
  );
};

ListWidget.propTypes = propTypes;

export default ListWidget;
