/* eslint-disable react/prop-types */
import React, { Fragment } from 'react';
import { graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Seo from '../components/Seo';
import BrandGrid from '../components/BrandGrid/BrandGrid';
import Post from '../components/Blog/Post';
import Sidebar from '../components/Blog/Sidebar';
import Pagination from '../components/Blog/Pagination';

export const pageQuery = graphql`
  query CategoryByIdQuery($id: String!, $skip: Int!, $limit: Int!) {
    wpCategory(id: { eq: $id }) {
      name
      seo {
        title
        metaDesc
        canonical
        schema {
          raw
        }
      }
    }

    allWpPost(
      filter: { categories: { nodes: { elemMatch: { id: { in: [$id] } } } } }
      sort: { fields: [date], order: DESC }
      skip: $skip
      limit: $limit
    ) {
      nodes {
        uri
        date(formatString: "MMMM DD, YYYY")
        title
        excerpt

        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 1110)
              }
            }
          }
        }

        author {
          node {
            uri
            name
          }
        }

        categories {
          nodes {
            name
            uri
          }
        }
      }
    }
  }
`;

const BlogCategoryPage = ({
  data,
  pageContext: { previousPagePath, nextPagePath },
}) => {
  const category = data.wpCategory;
  const posts = data.allWpPost.nodes;

  return (
    <>
      {!!category.seo && (
        <Seo
          title={category.seo.title}
          description={category.seo.metaDesc}
          canonical={category.seo.canonical}
          schema={category.seo.schema.raw}
        />
      )}

      <BrandGrid />

      <Container>
        <Row>
          <Col>
            <header className="ui-header mb-5">
              <h1 className="h1--center text-center">
                Category: {category.name}
              </h1>
            </header>
          </Col>
        </Row>
        <Row className="justify-content-lg-between">
          <Col xl={8}>
            {posts.map((post) => {
              const featuredImage = {
                image: getImage(post.featuredImage?.node?.localFile),
                alt: post.featuredImage?.node?.altText || ``,
              };

              return (
                <Fragment key={post.uri}>
                  <Post
                    uri={post.uri}
                    title={post.title}
                    featuredImage={featuredImage}
                    date={post.date}
                    author={post.author.node}
                    excerpt={post.excerpt}
                    categories={post.categories.nodes}
                  />
                  <hr className="no-border my-5" />
                </Fragment>
              );
            })}

            {(previousPagePath || nextPagePath) && (
              <Pagination previous={previousPagePath} next={nextPagePath} />
            )}
          </Col>
          <Col xl={3} as="aside" className="mt-5 mt-xl-0">
            <Sidebar />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default BlogCategoryPage;
