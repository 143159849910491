import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import ListWidget from './ListWidget';

const query = graphql`
  query BlogSidebarQuery {
    site {
      siteMetadata {
        blog {
          sidebarItems {
            uri
            name
          }
        }
      }
    }

    allWpCategory(
      sort: { fields: name, order: ASC }
      filter: { databaseId: { nin: [1] } }
    ) {
      nodes {
        uri
        name
      }
    }
  }
`;

const Sidebar = () => {
  const { site, allWpCategory } = useStaticQuery(query);
  const products = site.siteMetadata.blog.sidebarItems;
  const categories = allWpCategory.nodes;

  return (
    <>
      <ListWidget title="Top Products and Services" items={products} />
      {categories.length > 0 && (
        <ListWidget title="Categories" items={categories} />
      )}
    </>
  );
};

export default Sidebar;
