import React from 'react';
import { Link } from 'gatsby';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { string } from 'prop-types';

import { FaChevronRight } from '@react-icons/all-files/fa/FaChevronRight';
import { FaChevronLeft } from '@react-icons/all-files/fa/FaChevronLeft';

const propTypes = {
  previous: string,
  next: string,
};

const defaultProps = {
  previous: null,
  next: null,
};

const Pagination = ({ previous, next }) => {
  return (
    <Row className="justify-content-between">
      <Col>
        {previous && (
          <Link to={previous} className="ui-blog-nav-btn">
            <FaChevronLeft />
            Previous Page
          </Link>
        )}
      </Col>
      <Col className="text-end">
        {next && (
          <Link to={next} className="ui-blog-nav-btn">
            Next Page
            <FaChevronRight />
          </Link>
        )}
      </Col>
    </Row>
  );
};

Pagination.propTypes = propTypes;
Pagination.defaultProps = defaultProps;

export default Pagination;
